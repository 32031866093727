import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  targetTableFormSchema,
  mappingFieldsTableSchema,
  tsaFieldsTableSchema,
  TargetTableFormValues,
  MappingFieldsTableValues,
  TsaFieldsTableValues,
  TSA_VALUES,
} from '../schema';
import { useMemo } from 'react';

export const useTargetTableForms = (
  initialTableData?: Partial<TargetTableFormValues>,
  initialMappingData?: MappingFieldsTableValues,
  initialTsaData?: TsaFieldsTableValues
) => {
  // Main form
  const {
    control: tableControl,
    handleSubmit,
    watch: tableWatch,
    setValue: setTableValue,
    formState: { errors },
    reset: resetTableForm,
  } = useForm<TargetTableFormValues>({
    resolver: zodResolver(targetTableFormSchema),
    defaultValues: initialTableData || {
      tableName: '',
      description: '',
      dsp: false,
      submissionType: undefined,
      tableData: [
        {
          name: '',
          displayName: '',
          type: '',
          description: '',
          dsp: null,
        },
      ],
    },
  });

  // Mapping form
  const {
    control: mappingControl,
    watch: mappingWatch,
    setValue: setMappingValue,
    reset: resetMappingForm,
  } = useForm<MappingFieldsTableValues>({
    resolver: zodResolver(mappingFieldsTableSchema),
    defaultValues: initialMappingData || {
      fields: [
        {
          fieldName: '',
          referenceColumn: '',
          lookupTable: '',
          lookupColumn: '',
          description: '',
        },
      ],
    },
  });

  // TSA form
  const {
    control: tsaControl,
    watch: tsaWatch,
    setValue: setTsaValue,
    reset: resetTsaForm,
  } = useForm<TsaFieldsTableValues>({
    resolver: zodResolver(tsaFieldsTableSchema),
    defaultValues: initialTsaData || {
      requiredFields: [],
      fields: [
        {
          fieldName: '',
          tsa: TSA_VALUES.DISABLED,
        },
      ],
    },
  });

  // Watch values
  const tableData = tableWatch('tableData');
  const dsp = tableWatch('dsp');
  const mappingFieldsData = mappingWatch('fields');
  const tsaFieldsData = tsaWatch('fields');
  const requiredFields = tsaWatch('requiredFields');

  // Field name options for selects/autocomplete
  const fieldNames = useMemo(
    () => tableData?.map((field) => field.name) || [],
    [tableData]
  );

  const fieldNameOptions = useMemo(
    () =>
      fieldNames.map((fieldName) => ({
        label: fieldName,
        value: fieldName,
      })),
    [fieldNames]
  );

  // Helper functions for updating form state
  const setTableData = (
    updatedTableData: TargetTableFormValues['tableData']
  ) => {
    setTableValue('tableData', updatedTableData);
  };

  const setMappingTableData = (
    tableData: MappingFieldsTableValues['fields']
  ) => {
    setMappingValue('fields', tableData);
  };

  const setTsaTableData = (tableData: TsaFieldsTableValues['fields']) => {
    setTsaValue('fields', tableData);
  };

  return {
    // Forms
    tableForm: {
      control: tableControl,
      handleSubmit,
      errors,
      reset: resetTableForm,
    },
    mappingForm: {
      control: mappingControl,
      reset: resetMappingForm,
    },
    tsaForm: {
      control: tsaControl,
      reset: resetTsaForm,
    },

    // Watched values
    values: {
      tableData,
      dsp,
      mappingFieldsData,
      tsaFieldsData,
      requiredFields,
      fieldNames,
      fieldNameOptions,
    },

    // Update functions
    setTableData,
    setMappingTableData,
    setTsaTableData,
  };
};
