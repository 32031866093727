import {
  GraphQLReturn,
  graphQLSelector,
  useCherreValue,
} from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { useLibraryModelDetailsQuery } from './__generated__/useLibraryModelDetailsQuery.graphql';

const $libraryModelDetails = graphQLSelector({
  environment: 'dom',
  query: graphql`
    query useLibraryModelDetailsQuery($uid: String) {
      #TODO:@gaspardetienne97 use filters when available in the query
      # targetTableDefinitionsPackages({filters:{id:}})
      targetTableDefinitionsPackage(uid: $uid) {
        # Add id when it is available
        uid
        name
        displayName
        description
        owner {
          name
        }
        targetTableDefinitions {
          uid
          name
          displayName
          dsp {
            submissionType
          }
        }
      }
    }
  ` as GraphQLReturn<useLibraryModelDetailsQuery>,
  mapResponse: (resp) => {
    return resp.targetTableDefinitionsPackage;
  },
});

export const useLibraryModelDetails = (uid?: string) => {
  const modelDetails = uid
    ? useCherreValue($libraryModelDetails({ uid }))
    : null;

  return modelDetails;
};
