import {
  PageContainer,
  Panel,
  Button,
  Box,
  Grid2 as Grid,
  AddCircleIcon,
} from '@cherre-frontend/ui';
import React from 'react';
import LibraryBreadcrumbs from '../../components/LibraryBreadcrumbs';
import { useLibraryModelDetails } from '../../services/useLibraryModelDetails';
import LibraryCard from '../../components/LibraryCard';
import { generatePath, useParams } from 'react-router-dom';
import {
  ModelsLibraryRoute,
  ModelTableCreateRoute,
  ModelTableDetailsRoute,
} from '../../routes';
import Header from '../../components/ModelsLibraryHeader';
import ModelLibrarySection, {
  OrganizationOption,
} from '../../components/ModelLibrarySection';
import { useOrganitionsOptions } from '../../hooks/useOrganitionsOptions';

const ModelDetails: React.FC = () => {
  const { modelId } = useParams<{ modelId?: string }>();
  const modelDetails = useLibraryModelDetails(modelId);
  const { options: autoCompleteOptions } = useOrganitionsOptions();
  let selectedValues = ['Cherre', 'OTPP', 'Nuveen'];
  const onAutoCompleteChange = (option: OrganizationOption) => {
    selectedValues.push(option.value);
  };
  const onChipDelete = (value: string) => {
    selectedValues = selectedValues.filter(
      (selectedValue) => selectedValue !== value
    );
  };
  const handleDataGridClick = () => {
    //TODO: @gaspardetienne97 implement handleDataGridClick
    throw new Error('Function not implemented.');
  };
  const handleCityClick = () => {
    //TODO: @gaspardetienne97 implement handleCityClick
    throw new Error('Function not implemented.');
  };
  const handleCopyClick = () => {
    //TODO: @gaspardetienne97 implement handleCopyClick
    throw new Error('Function not implemented.');
  };
  return (
    <PageContainer id='ModelDetailsPage'>
      <Box display='flex' flexDirection='column' paddingY={'10px'} gap='20px'>
        <Panel id='ModelDetailsBreadcrumbs' behavior='fallback'>
          <LibraryBreadcrumbs
            modelName={modelDetails?.displayName ?? modelDetails?.name}
          />
        </Panel>
        <Panel id='ModelDetailsHeader' behavior='fallback'>
          <Header
            title={modelDetails?.displayName ?? modelDetails?.name ?? ''}
            goBackHref={generatePath(ModelsLibraryRoute.routeDescriptor.path)}
            goBackText='Back to Data Models'
          />
        </Panel>
        <Panel id='ModelDetailsOrganization' behavior='fallback'>
          <ModelLibrarySection
            title='Organizations'
            description='Select which organizations this model should be assigned to in
                  order for them to view the below model in their library. Note:
                  Assigning a standard data model does not assign all target
                  tables to the organization. Organizations need to be assigned
                  to target tables as well.'
            autoCompleteOptions={autoCompleteOptions}
            selectedValues={['Cherre', 'OTPP']}
            onAutoCompleteChange={onAutoCompleteChange}
            onChipDelete={onChipDelete}
          />
        </Panel>
        <Panel id='ModelDetailsFilters' behavior='fallback'>
          <Box
            display='flex'
            flexDirection='row'
            justifyContent='space-between'
          >
            <Box>{/* TODO:@gaspardetienne97 filters should go here */}</Box>
            {modelDetails && (
              <Button
                variant='contained'
                endIcon={<AddCircleIcon />}
                href={generatePath(ModelTableCreateRoute.routeDescriptor.path, {
                  modelId: modelDetails.uid,
                })}
              >
                Create Target Table
              </Button>
            )}
          </Box>
        </Panel>
        <Panel id='ModelDetailsContent' behavior='fallback'>
          <Grid container spacing={3}>
            {modelDetails?.targetTableDefinitions?.map((table) => {
              return (
                <Grid key={table.name} xs={12} sm={6} md={4} lg={3}>
                  <LibraryCard
                    cardTitle={table.displayName ?? table.name}
                    viewLink={generatePath(
                      ModelTableDetailsRoute.routeDescriptor.path,
                      { modelId: modelDetails.uid, tableId: table.uid }
                    )}
                    onDataGridClick={handleDataGridClick}
                    onCityClick={handleCityClick}
                    onCopyClick={handleCopyClick}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Panel>
      </Box>
    </PageContainer>
  );
};

ModelDetails.displayName = 'ModelDetails';
export default ModelDetails;
