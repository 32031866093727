import React from 'react';
import {
  Box,
  Paper,
  Typography,
  Chip,
  Stack,
  MuiAutocomplete as Autocomplete,
  TextField,
} from '@cherre-frontend/ui';

export interface OrganizationOption {
  label: string;
  value: string;
}
interface ModelLibrarySectionProps {
  title: string;
  description: string;
  autoCompleteOptions: OrganizationOption[];
  selectedValues: string[];
  onAutoCompleteChange: (value: OrganizationOption) => void;
  onChipDelete: (value: string) => void;
}
export const ModelLibrarySection: React.FC<ModelLibrarySectionProps> = ({
  title,
  description,
  autoCompleteOptions,
  onAutoCompleteChange,
  selectedValues,
  onChipDelete,
}) => {
  return (
    <Paper
      sx={{
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
      }}
    >
      <Box display='flex' flexDirection='column' gap='8px'>
        <Box display='flex' flexDirection='column' gap='4px'>
          <Typography variant='h6'>{title}</Typography>
          <Typography variant='body1'>{description}</Typography>
        </Box>

        <Autocomplete
          isOptionEqualToValue={(option, value) => {
            return option.label === value.label;
          }}
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              color='primary'
              placeholder='Select'
              size='small'
            />
          )}
          onChange={(_, value) => {
            onAutoCompleteChange(value as OrganizationOption);
          }}
          options={autoCompleteOptions}
        />

        <Box display='flex' flexDirection='column' gap='8px'>
          <Typography variant='subtitle1'>Assigned</Typography>
          <Stack direction='row' spacing={1}>
            {selectedValues.map((value) => (
              <Chip
                data-testid='ModelLibrarySectionChip'
                key={value}
                label={value}
                color='primary'
                size='small'
                onDelete={(e) => onChipDelete(e.t)}
              />
            ))}
          </Stack>
        </Box>
      </Box>
    </Paper>
  );
};

ModelLibrarySection.displayName = 'ModelLibrarySection';
export default ModelLibrarySection;
