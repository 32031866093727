import {
  PageContainer,
  Panel,
  Box,
  AddCircleIcon,
  Button,
  Grid2 as Grid,
} from '@cherre-frontend/ui';
import React from 'react';
import LibraryBreadcrumbs from '../../components/LibraryBreadcrumbs';
import { useLibraryModelsList } from '../../services/useLibraryModelsList';
import LibraryCard from '../../components/LibraryCard';
import { generatePath } from 'react-router-dom';
import { ModelCreateRoute, ModelDetailsRoute } from '../../routes';
import Header from '../../components/ModelsLibraryHeader';

const ModelsLibrary: React.FC = () => {
  const modelsList = useLibraryModelsList();

  const handleCityClick = () => {
    //TODO: @gaspardetienne97 implement handleCityClick
    throw new Error('Function not implemented.');
  };
  const handleCopyClick = () => {
    //TODO: @gaspardetienne97 implement handleCopyClick
    throw new Error('Function not implemented.');
  };
  return (
    <PageContainer id='ModelsLibraryPage'>
      <Box display='flex' flexDirection='column' paddingY={'10px'} gap='20px'>
        <Panel id='ModelsLibraryBreadcrumbs' behavior='fallback'>
          <LibraryBreadcrumbs />
        </Panel>
        <Panel id='ModelsLibraryHeader' behavior='fallback'>
          <Header
            title='Data Models Library'
            subtitle='Explore all your data models below and select one to view its associated target tables.'
            headerImage='/assets/products-artwork/ModelsLibrary.svg'
          />
        </Panel>
        <Panel id='ModelsLibraryFilterBar' behavior='retry'>
          <Box
            display='flex'
            flexDirection='row'
            justifyContent='space-between'
          >
            <Box
              display='flex'
              flexDirection='row'
              gap='20px'
              justifyContent='space-between'
            >
              {/*TODO:@gaspardetienne97  filters should go here */}
            </Box>
            <Button
              variant='contained'
              endIcon={<AddCircleIcon />}
              href={generatePath(ModelCreateRoute.routeDescriptor.path)}
            >
              Custom Data Model
            </Button>
          </Box>
        </Panel>
        <Panel id='ModelsLibraryContent' behavior='fallback'>
          <Grid container spacing={3}>
            {modelsList?.map((model) => {
              return (
                <Grid key={model.name} xs={12} sm={6} md={4} lg={3}>
                  <LibraryCard
                    cardTitle={model.displayName ?? model.name}
                    viewLink={generatePath(
                      ModelDetailsRoute.routeDescriptor.path,
                      { modelId: model.uid }
                    )}
                    onCityClick={handleCityClick}
                    onCopyClick={handleCopyClick}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Panel>
      </Box>
    </PageContainer>
  );
};

ModelsLibrary.displayName = 'ModelsLibrary';
export default ModelsLibrary;
