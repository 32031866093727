import {
  GraphQLReturn,
  useMutationAsync,
} from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { useModelTableCreateMutation } from './__generated__/useModelTableCreateMutation.graphql';

export const useModelTableCreate = () => {
  return useMutationAsync(
    graphql`
      mutation useModelTableCreateMutation(
        $targetTableDefinitionName: String!
        $displayName: String!
        $description: String
        $dsp: TargetTableDspDefinitionInput
        $columns: [ColumnInput!]!
      ) {
        createTargetTableDefinition(
          targetTableDefinitionName: $targetTableDefinitionName
          displayName: $displayName
          description: $description
          dsp: $dsp
          columns: $columns
        ) {
          name
          description
          displayName
          uid
          owner {
            name
          }
          dsp {
            submissionType
          }
          columns {
            name
            displayName
            type
            description
            dsp {
              required
              mappingFields {
                name
                targetName
                displayName
                required
                tsa {
                  required
                  enabled
                }
              }
              tsa {
                required
                enabled
              }
            }
          }
        }
      }
    ` as GraphQLReturn<useModelTableCreateMutation>
  );
};
